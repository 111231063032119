import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NxHttpService } from './services/nx-http.service';
import { NgxsModule } from '@ngxs/store';
import { LayoutState } from './state/states/layout.state';
import { environment } from '../environments/environment';
import { MainNavComponent } from './main-nav/main-nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatsComponent } from './stats/stats.component';
import { AdminComponent } from './stats/admin/admin.component';
import { SchoolComponent } from './stats/school/school.component';
import { RegionManagerComponent } from './stats/region-manager/region-manager.component';
import { ApmModule,ApmService } from '@elastic/apm-rum-angular';

@NgModule({
    declarations: [
        AppComponent,
        MainNavComponent,
        LoginComponent,
        DashboardComponent,
        StatsComponent,
        AdminComponent,
        SchoolComponent,
        RegionManagerComponent,

    ],
    imports: [
        BrowserModule,
        SharedModule,
        ApmModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxsModule.forRoot([
            LayoutState,
        ], { developmentMode: !environment.production })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NxHttpService,
            multi: true
        },
        [AuthGuard],
        [ApmService]

    ], bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(service: ApmService) {
        // Agent API is exposed through this apm instance
        const apm = service.init({
          serviceName: 'hologo-app-dashboard',
          serverUrl: 'https://e137873ba47c4c6cb3817fade013fd3a.apm.ap-southeast-1.aws.cloud.es.io:443',
          
        })
    
        apm.setUserContext({
          'username': 'chosen',
          'id': 'bar'
        })
      }
}
